import { INTIALIZE_SUCCESS, SAVE_LANGUAGES, SAVE_ZONES, RESET_INTIAL_DATA ,IS_FEEDBACK_REQUIRED} from '../types/index';
const intialState = {
  isLoaded: false,
  languages: [],
  zones: [],
  isFeedbackRequired : false,
};

const intializeReducer = (state = intialState, action) => {
  switch (action.type) {
    case INTIALIZE_SUCCESS:
      return { ...state, isLoaded: action.payload };
    case SAVE_LANGUAGES:
      return { ...state, languages: [...action.payload] };
    case SAVE_ZONES:
      return { ...state, zones: [...action.payload] };
    case IS_FEEDBACK_REQUIRED:
      return { ...state, isFeedbackRequired: action.payload}  
    case RESET_INTIAL_DATA:
      return intialState
    default:
      return state;
  }
};

export default intializeReducer;
