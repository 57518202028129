import React, { useState, useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import setScreen from '../../utils/screenTransistion';
const Registration = lazy(() => import('../registration'));
const CurrentComponent = lazy(() => import('../currentcomponent'));

const GameStart = ({
  currentScreen,
  messages,
  levels,
  shuffleData,
  images,
  isFeedbackRequired,
}) => {
  const [component, setComponent] = useState(<Registration />);

  useEffect(() => {
    setScreen(currentScreen, setComponent, component, messages, levels, images,isFeedbackRequired);
    // eslint-disable-next-line
  }, [currentScreen, shuffleData]);

  return (
    <>
      <Suspense fallback={<div>Loading</div>}>
        <CurrentComponent>{component}</CurrentComponent>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    isSubmitted: state.trackingUser.isSubmitted,
    isLoaded: state.trackingUser.isLoaded,
    messages: state.shuffleData.narrationDataSet.narrationDataList,
    currentScreen: state.screenChange.screenID,
    levels: state.shuffleData.levels,
    images: state.shuffleData.imageDataSet.imageDataList,
    isFeedbackRequired: state.intialize.isFeedbackRequired,
  };
};

export default connect(mapStateToProps)(GameStart);
