import axios from "axios";

export const headers = {
  "Tenant-Access-Token": "MjQ0OWE1NmNlMTY5MDZjZjMyNmFjNjEwMzgzMTA5OGU=",
  "Tenant-Access-Key": "E6TzCKzCQj-2iw",
};
export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
  baseURL: BASE_URL,
  headers,
});
