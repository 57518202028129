import { useEffect, useState } from 'react';
import './style.scss';
import SplashScreen from '../splashscreen';
import ErrorBoundary from '../errorboundary';
import GameStart from '../gamestart';
import { connect } from 'react-redux';
import { intialize } from '../../store/intialize/operations';
import CuLayout from '../../utils/gameLayout';
import { Alert } from '@material-ui/lab';

function App({ isLoaded, intialize }) {
  const [failedIntilize, setFailedIntilize] = useState(false);
  const [msg] = useState(
    `Couldn't load the request. Please check your internet connection and retry`
  );
  useEffect(() => {
    handleClick();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    intialize(
      () => {
        setFailedIntilize(false);
      },
      (err) => {
        setFailedIntilize(true);
      }
    );
  };

  if (failedIntilize) {
    return (
      <ErrorBoundary>
        <div className="app_alert">
          <Alert onClick={handleClick} severity="error">
            <h3>{msg}</h3>
          </Alert>
        </div>
      </ErrorBoundary>
    );
  } else {
    return (
      <>
        <ErrorBoundary>
          {!isLoaded ? (
            <SplashScreen />
          ) : (
            <CuLayout>
              <GameStart />
            </CuLayout>
          )}
        </ErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoaded: state.intialize.isLoaded,
    languages: state.intialize.languages,
  };
};

const mapDisPatchToProps = (dispatch) => {
  return {
    intialize: (success, failure) => dispatch(intialize(success, failure)),
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(App);
