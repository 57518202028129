import React, { useState, useEffect } from 'react';
import { intialLoading, setIndex } from '../../store/screentransistion/actions';
import { saveUserDetails, resetUserState, resetShuffleData } from '../../store/trackinguser/actions';
import Layout from '../common/layoutred';
import Buttonn from '../../assets/images/backgrounds/submit_button.png';
import { fetchShuffle } from '../../store/shuffledata/operations';
import { Alert } from '@material-ui/lab';
import { isAndroid } from 'react-device-detect';
import './style.scss';
import fullScreen from '../../utils/fullScreen';
import { connect } from 'react-redux';
import {
  toggleMusic,
  toggleVoiceOver,
} from '../../store/screentransistion/actions';
import music from '../../assets/audio/BGMusic.mp3';
import PlayMusic from '../../utils/playMusic';
window.gtag('event', 'REGISTRATION', {
  'event_category': 'REGISTRATION',
  'event_label': 'REGISTRATION'
});


const Registration = ({
  fetchShuffle,
  shuffleData,
  progress,
  languages,
  zones,
  saveUserDetails,
  audio,
  intialLoading,
  setIndex,
  gameSessionId,
  language,
  toggleMusic,
  toggleVoiceOver,
  resetShuffleData,
  resetUserState,
}) => {
  const [india] = zones;
  const [english] = languages;
  //const GAEventsTracker = eventTrack("External Links");

  const [data, setData] = useState({
    zone: india._id,
    lang: english.value,
    number: '',
  })

  const activate = fullScreen();

  const [error, setError] = useState(false);
  const [msg, setMsg] = useState('Please enter valid details');



  const [validNumber, setValidNumber]=useState("")
  useEffect(() => {
    if (data.lang === 'en') {
      setValidNumber("Please enter valid number")
    }

    if (data.lang === 'hi') {
      setValidNumber("कृपया वैध संख्या दर्ज करें")
    }
    if (data.lang === 'kn') {
      setValidNumber("ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ")
    }
    if (data.lang === 'ta') {
      setValidNumber("சரியான எண்ணை உள்ளிடவும்")
    }
    if (data.lang === 'te') {
      setValidNumber("దయచేసి చెల్లుబాటు అయ్యే నంబర్‌ని నమోదు చేయండి")
    }
    if (data.lang === 'pa') {
      setValidNumber("ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਨੰਬਰ ਦਾਖਲ ਕਰੋ")
    }
    if (data.lang === 'gu') {
      setValidNumber("કૃપા કરીને માન્ય નંબર દાખલ કરો")
    }
    if (data.lang === 'mr') {
      setValidNumber("कृपया वैध क्रमांक प्रविष्ट करा")
    }
  },[data.lang])

  const [maxNo,setMaxNo]=useState("")
  useEffect(()=>{
    if(data.lang === 'en'){
      setMaxNo("Maximum 10 numbers allowed")
    }
    if(data.lang === 'hi'){
      setMaxNo("अधिकतम 10 नंबरों की अनुमति है")
    }
    if(data.lang === 'mr'){
      setMaxNo("कमाल 10 संख्यांना परवानगी आहे")
    }
    if(data.lang === 'kn'){
      setMaxNo("ಗರಿಷ್ಠ 10 ಸಂಖ್ಯೆಗಳನ್ನು ಅನುಮತಿಸಲಾಗಿದೆ")
    }
    if(data.lang === 'ta'){
      setMaxNo("அதிகபட்சம் 10 எண்கள் அனுமதிக்கப்படும்")
    }
    if(data.lang === 'te'){
      setMaxNo("గరిష్టంగా 10 సంఖ్యలు అనుమతించబడతాయి")
    }
    if(data.lang === 'pa'){
      setMaxNo("ਅਧਿਕਤਮ 10 ਨੰਬਰਾਂ ਦੀ ਇਜਾਜ਼ਤ ਹੈ")
    }
    if(data.lang === 'gu'){
      setMaxNo("મહત્તમ 10 નંબરની મંજૂરી")
    }
  },[data.lang])
  const handleChange = (event) => {
    const { name, value } = event.target;


    const rExp = /^[0-9\b]+$/;

    if (name === 'number') {
      if (rExp.test(event.target.value)) {
        if (event.target.value.length <= 10) {
          setData({ ...data, [name]: value });
          setError(false);
        } else {
          setError(true);
          setMsg(maxNo);
          return;
        }
      } else {
        setData({ ...data, [name]: '' });
        setError(true);
        setMsg('Please enter a number');
        return;
      }
    }

    setData({ ...data, [name]: value });
  };

  const register = (event) => {
    // GAEventsTracker('reg')
    // ReactGA.event({
    //   category: "user_registration",
    //   action: "user_registration",
    // });
    event.preventDefault();
    if (data.number.length !== 10) {
      setError(true);
      setMsg(validNumber);
      return;
    }
    if (data.number.length === 10 && data.lang && data.zone) {
      resetShuffleData();
      resetUserState()
      fetchShuffle(
        [data.lang, data.number],
        () => {
          // This is done so that audio controls are initiated as part of a user action
          toggleVoiceOver(true);
          toggleMusic(true);
          const audioRef = PlayMusic(music);
          audioRef.pause();

          intialLoading();
          setIndex(0);
          saveUserDetails(data);
          if (isAndroid) {
            activate();
          }
        },
        (err) => {
          setError(true);
          setMsg('NetWork Failed Please Check Network Connection');
        }
      );
    } else {
      setError(true);
      setMsg('All fields are required');
    }
  };
  //language localization
  const [number, setNumber] = useState("")
  useEffect(() => {
    if (data.lang === 'en') {
      setNumber("Enter Phone Number")
    }
    if (data.lang === 'hi') {
      setNumber("फोन नंबर दर्ज")
    }
    if (data.lang === 'kn') {
      setNumber("ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ")
    }
    if (data.lang === 'ta') {
      setNumber("தொலைபேசி எண்ணை உள்ளிடவும்")
    }
    if (data.lang === 'te') {
      setNumber("ఫోన్ నంబర్‌ని నమోదు చేయండి")
    }
    if (data.lang === 'pa') {
      setNumber("ਫ਼ੋਨ ਨੰਬਰ ਦਰਜ ਕਰੋ")
    }
    if (data.lang === 'gu') {
      setNumber("ફોન નંબર દાખલ કરો")
    }
    if (data.lang === 'mr') {
      setNumber("फोन नंबर प्रविष्ट करा")
    }
  })
  const [start, setStart] = useState('')
  useEffect(() => {
    if (data.lang === 'en') {
      setStart("Start")
    }
    if (data.lang === 'hi') {
      setStart("प्रारंभ")
    }
    if (data.lang === 'kn') {
      setStart("ಪ್ರಾರಂಭಿಸಿ")
    }
    if (data.lang === 'ta') {
      setStart("தொடங்கு")
    }
    if(data.lang === 'te'){
      setStart("ప్రారంభించండి")
    }
    if(data.lang === 'pa'){
      setStart("ਸ਼ੁਰੂ ਕਰੋ")
    }
    if(data.lang === 'gu'){
      setStart("શરૂઆત")
    }    
    if (data.lang === 'mr') {
      setStart("सुरू करा")
    }
  })
  
  return (
    <>
      <Layout>
        <div className="registration__container">
          <form onSubmit={register}>
            <div className="form__control">
              <select name="lang" value={data.lang} onChange={handleChange}>
                {languages
                  ? languages.map((lan) => (
                    <option key={lan.value} value={lan.value}>
                      {lan.language}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            {/* <div className="form__control">
              <select
                name="country"
                value={data.country}
                onChange={handleChange}
              >
                {zones
                  ? zones.map((zone) => (
                      <option key={zone._id} value={zone._id}>
                        {zone.zoneName}
                      </option>
                    ))
                  : null}
              </select>
            </div> */}
            <div className="form__control">
              <input
                type="number"
                // placeholder="Enter phone Number"
                placeholder={number}
                name="number"
                value={data.number}
                onChange={handleChange}
              />
            </div>
            
            <div
              type="submit"
              className="form__control start_button"
              onClick={register}
            >
              <img src={Buttonn} alt="start_image" />
              <div type="submit" className="button__text">
                {start}
              </div>
            </div>
            <div className="form__control error__msg">
              {error ? (
                <Alert severity="error">
                  <h3>{msg}</h3>
                </Alert>
              ) : null}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    progress: state.progress,
    languages: state.intialize.languages,
    zones: state.intialize.zones,
    audio: state.shuffleData,
    language: state.trackingUser.userDeatils,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    fetchShuffle: (language, success, failure) =>
      dispatch(fetchShuffle(language, success, failure)),
    saveUserDetails: (details) => dispatch(saveUserDetails(details)),
    intialLoading: () => dispatch(intialLoading()),
    setIndex: (index) => dispatch(setIndex(index)),
    toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
    toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
    resetShuffleData: () => dispatch(resetShuffleData()),
    resetUserState: () => dispatch(resetUserState()),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(Registration);
