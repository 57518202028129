import {
  SAVE_USER_REGISTRATION_INFO,
  SAVE_LEVEL_INFO,
  ADD_COINS,
  VEHICLE_SELECTION,
  SAVE_USER_SESSION_ID,
  SAVE_AVATAR_ID,
  ASK_BARGAIN,
  FIRST_BARGAIN_VALUE,
  SECOND_BARGAIN_VALUE,
  SAVE_PICTURE_GRAM,
  SAVE_SHOPPING_APP,
  SAVE_THREE_IMAGES,
  SAVE_NUMERACY,
  TWO_IMAGE_SELECTION_TIME,
  PICTUREGRAM_SELECTION_TIME,
  THREE_IMAGE_SELECTION_TIME,
  NUMERACY_SELECTION_TIME,
  TOTAL_TIME_TAKEN_IN_SEC,
  TOTAL_TIME_TAKEN_IN_MILLI_SEC,
  SAVE_RESPONSE,
  RESET_USER_DATA,
  RESET_SHUFFLE_DATA,
  RESET_INTIAL_DATA,
  RESET_DATA,
} from '../types';

export const saveUserDetails = (details) => {
  return {
    type: SAVE_USER_REGISTRATION_INFO,
    payload: details,
  };
};

export const saveUserSessionId = (id) => {
  return {
    type: SAVE_USER_SESSION_ID,
    payload: id,
  };
};

export const saveAvatarId = (id) => {
  return {
    type: SAVE_AVATAR_ID,
    payload: id,
  };
};

export const askBargain = (bargain) => {
  return {
    type: ASK_BARGAIN,
    payload: bargain,
  };
};

export const firstBargain = (value) => {
  return {
    type: FIRST_BARGAIN_VALUE,
    payload: value,
  };
};

export const secondBargain = (value) => {
  return {
    type: SECOND_BARGAIN_VALUE,
    payload: value,
  };
};

export const saveShoppingApp = (cart) => {
  return {
    type: SAVE_SHOPPING_APP,
    payload: cart,
  };
};

export const savePictureGram = (pict) => {
  return {
    type: SAVE_PICTURE_GRAM,
    payload: pict,
  };
};
export const saveThreeImages = (obj) => {
  return {
    type: SAVE_THREE_IMAGES,
    payload: obj,
  };
};

export const saveNumeracy = (obj) => {
  return {
    type: SAVE_NUMERACY,
    payload: obj,
  };
};

export const saveLevels = (details) => {
  return {
    type: SAVE_LEVEL_INFO,
    payload: details,
  };
};

export const addCoins = (coins) => {
  return {
    type: ADD_COINS,
    payload: coins,
  };
};

export const twoImageTotalTime = (time) => {
  return {
    type: TWO_IMAGE_SELECTION_TIME,
    payload: time,
  };
};

export const picturegramTotalTime = (time) => {
  return {
    type: PICTUREGRAM_SELECTION_TIME,
    payload: time,
  };
};

export const threeImagesTotalTime = (time) => {
  return {
    type: THREE_IMAGE_SELECTION_TIME,
    payload: time,
  };
};

export const numeracyTotalTime = (time) => {
  return {
    type: NUMERACY_SELECTION_TIME,
    payload: time,
  };
};

export const totalTimeTakenInSec = (time) => {
  return {
    type: TOTAL_TIME_TAKEN_IN_SEC,
    payload: time,
  };
};

export const totalTimeTakenInMilliSec = (time) => {
  return {
    type: TOTAL_TIME_TAKEN_IN_MILLI_SEC,
    payload: time,
  };
};

export const saveResponse = (data) => {
  return {
    type: SAVE_RESPONSE,
    payload: data,
  };
};

export const resetUserState = () => {
  return {
    type: RESET_USER_DATA,
  };
};

export const resetShuffleData = () => {
  return {
    type: RESET_SHUFFLE_DATA
  }
}

export const resetIntialData = () => {
  return {
    type: RESET_INTIAL_DATA
  }
}

export const resetState = () => {
  return {
    type: RESET_DATA,
  };
};

export const vehicleType = (vehicle) => {
  return {
    type: VEHICLE_SELECTION,
    payload: vehicle,
  };
};
