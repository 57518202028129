import { api } from '../../utils/authAxios';
import { intializeSuccess, saveLanguages, saveZones, isFeedbackRequired} from './actions';

export const intialize = (success, fail) => {
  return (dispatch) => {
    api
      .post('/api/game/initialize')
      .then((res) => {
        dispatch(intializeSuccess(true));
        dispatch(saveLanguages(res.data.languageList));
        dispatch(saveZones(res.data.zoneList));
        dispatch(isFeedbackRequired(res.data.profileFeedbackRequired));
        success();
      })
      .catch((err) => {
        fail(err);
      });
  };
};
