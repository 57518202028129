import {
  SAVE_USER_REGISTRATION_INFO,
  SAVE_LEVEL_INFO,
  Is_USER_SUBMITTED_DATA,
  IS_LOADED,
  VEHICLE_SELECTION,
  SAVE_USER_SESSION_ID,
  SAVE_AVATAR_ID,
  ASK_BARGAIN,
  FIRST_BARGAIN_VALUE,
  SECOND_BARGAIN_VALUE,
  SAVE_PICTURE_GRAM,
  SAVE_SHOPPING_APP,
  SAVE_THREE_IMAGES,
  SAVE_NUMERACY,
  ADD_COINS,
  TWO_IMAGE_SELECTION_TIME,
  THREE_IMAGE_SELECTION_TIME,
  PICTUREGRAM_SELECTION_TIME,
  NUMERACY_SELECTION_TIME,
  TOTAL_TIME_TAKEN_IN_SEC,
  TOTAL_TIME_TAKEN_IN_MILLI_SEC,
  RESET_USER_DATA,
} from '../types';

const intialState = {
  user: {
    lang: '',
    zone: '',
    number: '',
    gameSessionId: '',
    coins: 0,
    totalTimeTakenSecs: 0,
    totalTimeTakenMillis: 0,
    level1TotalTimeTakenMillis: 0,
    numeracyTotalTimeTakenMillis: 0,
    level3TotalTimeTakenMillis: 0,
    level4TotalTimeTakenMillis: 0,
    avatar: {
      category: '',
      image_id: '',
      url: '',
    },
  },
  situations: {
    levelType: 'SITUATIONS',
    questions: [
      {
        questionId: 'SQ_TRANSPORT',
        optionId: '',
        timeTaken: null,
      },
      {
        questionId: 'SQ_BARGAIN',
        optionId: '',
        timeTaken: null,
      },
      {
        questionId: 'SQ_BARGAIN_1',
        optionId: '',
        timeTaken: null,
      },
      {
        questionId: 'SQ_BARGAIN_2',
        optionId: '',
        timeTaken: null,
      },
    ],
  },
  levels: {
    level1: {
      levelType: 'Level 1',
      questions: [],
    },
    level2: {
      levelType: 'numeracy',
      questions: [],
    },
    level3: {
      levelType: 'Level 3',
      questions: [],
    },
    level4: {
      levelType: 'Level 4',
      questions: [],
    },
  },
  purchaseList: null,
};

const userTrackingReducer = (state = intialState, action) => {
  switch (action.type) {
    case SAVE_USER_REGISTRATION_INFO:
      return { ...state, user: { ...state.user, ...action.payload } };
    case SAVE_USER_SESSION_ID:
      return {
        ...state,
        user: { ...state.user, gameSessionId: action.payload },
      };
    case TOTAL_TIME_TAKEN_IN_SEC:
      return {
        ...state,
        user: { ...state.user, totalTimeTakenSecs: action.payload },
      };
    case TOTAL_TIME_TAKEN_IN_MILLI_SEC:
      return {
        ...state,
        user: { ...state.user, totalTimeTakenMillis: action.payload },
      };
    case ADD_COINS:
      return {
        ...state,
        user: { ...state.user, coins: state.user.coins + action.payload },
      };
    case TWO_IMAGE_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level3TotalTimeTakenMillis: action.payload },
      };
    case THREE_IMAGE_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level4TotalTimeTakenMillis: action.payload },
      };
    case PICTUREGRAM_SELECTION_TIME:
      return {
        ...state,
        user: { ...state.user, level1TotalTimeTakenMillis: action.payload },
      };
    case NUMERACY_SELECTION_TIME:
      return {
        ...state,
        user: {
          ...state.user,
          numeracyTotalTimeTakenMillis:
            state.user.numeracyTotalTimeTakenMillis + action.payload,
        },
      };
    case SAVE_AVATAR_ID:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: { ...state.user.avatar, category: action.payload },
        },
      };
    case SAVE_LEVEL_INFO:
      return { ...state, levelDetails: action.payload };
    case Is_USER_SUBMITTED_DATA:
      return { ...state, isSubmitted: action.payload };
    case IS_LOADED:
      return { ...state, isLoaded: action.payload };
    case VEHICLE_SELECTION:
      const index = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let ques = state.situations.questions;
      ques.splice(index, 1, action.payload);

      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...ques],
        },
      };
    case ASK_BARGAIN:
      const indexB = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bques = state.situations.questions;
      bques.splice(indexB, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bques],
        },
      };
    case FIRST_BARGAIN_VALUE:
      const indexBF = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bquesBF = state.situations.questions;
      bquesBF.splice(indexBF, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bquesBF],
        },
      };
    case SECOND_BARGAIN_VALUE:
      const indexL = state.situations.questions.findIndex(
        (question) => question.questionId === action.payload.questionId
      );
      let bquesL = state.situations.questions;
      bquesL.splice(indexL, 1, action.payload);
      return {
        ...state,
        situations: {
          ...state.situations,
          questions: [...bquesL],
        },
      };
    case SAVE_PICTURE_GRAM:
      return {
        ...state,
        levels: {
          ...state.levels,
          level1: {
            ...state.levels.level1,
            questions: [...state.levels.level1.questions, action.payload],
          },
        },
      };
    case SAVE_SHOPPING_APP:
      return {
        ...state,
        levels: {
          ...state.levels,
          level3: {
            ...state.levels.level3,
            questions: [...state.levels.level3.questions, action.payload],
          },
        },
      };
    case SAVE_THREE_IMAGES:
      return {
        ...state,
        levels: {
          ...state.levels,
          level4: {
            ...state.levels.level4,
            questions: [...state.levels.level4.questions, action.payload],
          },
        },
      };
    case SAVE_NUMERACY:
      return {
        ...state,
        levels: {
          ...state.levels,
          level2: {
            ...state.levels.level2,
            questions: [...state.levels.level2.questions, action.payload],
          },
        },
      };
    case RESET_USER_DATA:
      return intialState;
    default:
      return state;
  }
};

export default userTrackingReducer;
